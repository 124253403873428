import React, {useState} from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import tw, { styled } from 'twin.macro'
import { LocaleContext } from '../components/layout'
import background from '../images/bluehexagon_faded.svg'
import KeyBenefits from '../components/solutions/key-benefits'
import WatchElementAnimate from '../components/watch-element-animate'
import Help from '../components/services/help'
import Testimonial from '../components/home/testimonial'
import Approach from '../components/services/approach'
import AIWork from '../components/services/ai-work'
import SEO from '../components/SEO/SEO'
import { withPreview } from 'gatsby-source-prismic'
import LocalizedLink from '../components/localized-link'
import {
  LargeSolidTurquoiseButton,
  LargeOutlineTealButton,
} from '../utils/styles'
import ModalVideo from 'react-modal-video'
import smile from '../images/smile.png'
import chidog from '../images/chidog.png'
import fire from '../images/fire.png'
import peachdish from '../images/peachdish.png'
import smile_project from '../images/smile-project.jpg'
import smile_logo from '../images/smile-logo.png'
import chidog_project from '../images/chidog-project.jpg'
import chidog_logo from '../images/chidog-logo.png'
import fire_project from '../images/fire-project.jpg'
import fire_logo from '../images/Fire-logo.png'
import peachdish_project from '../images/peachdish-project.jpg'
import peachdish_logo from '../images/peachdish-logo.png'
import perfect_project from '../images/perfect-project.jpg'
import perfect_logo from '../images/perfect-logo.png'
// styles
const HeroWrapper = styled.div`
  background:#030406;
  padding: 138px 0;
  background-position: 200% 68%;
  @media (max-width: 640px) {
    background-size: 120% 100%;
    background-position: 100% 20%;
    padding-bottom: 30px;
  }
`
const ServiceItemWrapper = styled.div`
  padding: 80px 0;
  ul {
    list-style: circle;
    li {
      padding: 0.25rem
    }
  }
`
// markup
const ProjectPage = ({
    pageContext: { locale },
  }) => {
  const lang = React.useContext(LocaleContext)
  const i18n = lang.i18n[lang.locale]
  const [isOpen, setIsOpen] = useState(null)
  return (
    <main>
     <SEO
        title={"Project"}
        desc={"Project"}
        locale={locale}
      />
      <HeroWrapper>
        <div tw="container">
          <div>
            <div tw="whitespace-normal">
             
              <div
                tw="text-white">
                    <h1>Projects </h1>
                </div>
              <p tw="text-xl text-white py-8">
              We’ve picked some of our absolute favourite work to share with you
              </p>
            </div>
          </div>
        </div>
      </HeroWrapper>
      <ServiceItemWrapper>
        <div tw="container">
        <div tw="flex flex-col gap-16">
        <div tw="flex flex-col">
          <div tw="grid grid-cols-2 gap-8 py-12 items-center justify-between w-full">
              <div>
                <h2 tw="pb-4 break-words">Headless + WooCommerce + growth</h2>
                <img src={smile_logo} tw="w-40"/>
              </div>
              <div>
                <img src={smile_project} tw="cursor-pointer" onClick={() => setIsOpen("smile")}/>
              </div>
          </div>
          <div tw="grid grid-cols-2 gap-8 justify-between w-full">
              <div>
               <p>It's a complex headless E-commerce project, we're using Wordpress/WooCommerce as our backend to process orders.</p>
               <p>The whole frontend is built with Gatsby including checkout flow.</p>
              </div>
              <div>
               <p>It's one backend for multiple websites, we also built several landing page on other domains for marketing.</p>
               <p>It's a smooth and joyful development process, we  host our codes on Github for continuous deployment on Netlify.</p>
              </div>
          </div>
            <ModalVideo
            channel="youtube"
            isOpen={"smile" === isOpen}
            videoId="hsHfan7sFjQ"
            youtube={{
                autoplay: 1,
                mute: 1,
            }}
            onClose={() => setIsOpen(null)}
            />
          </div>
          <div tw="flex flex-col">
          <div tw="grid grid-cols-2 gap-8 py-12 items-center justify-between w-full">
                <div>
                <img src={chidog_project} tw="cursor-pointer"/>
              </div>
              <div>
                <h2 tw="pb-4 break-words">WooCommerce + growth</h2>
                <img src={chidog_logo} tw="w-40"/>
              </div>
          </div>
          <div tw="grid grid-cols-2 gap-8 justify-between w-full">
              <div>
               <p>We built Wordpress them from scratch and customize the checkout flow for this dog food business.</p>
               <p>It's a complex subscription modal, we have to customize ShipStation plugin to match different tray size.</p>
              </div>
              <div>
               <p>We also developed the ShipStation integration on AWS lambda to pick the correct shipping service type so we can make sure the box will be delivered within 2 days also the most economical option.</p>
              </div>
          </div>
            
          </div>
          <div tw="flex flex-col">
          <div tw="grid grid-cols-2 gap-8 py-12 items-center justify-between w-full">
              <div>
                <h2 tw="pb-4 break-words">Headless + Shopify</h2>
                <img src={fire_logo} tw="w-40"/>
              </div>
              <div>
                <img src={fire_project} tw="cursor-pointer" onClick={() => setIsOpen("fire")}/>
              </div>
          </div>
          <div tw="grid grid-cols-2 gap-8 justify-between w-full">
              <div>
               <p>We developed the main site with Gatsby based the design on Figma, it's a challenge to make the loader work well with the background video we're going to show, need to make sure the video start to play. There are infinite scroll feature for the landing page, product slider.</p>
              </div>
              <div>
               <p>We built the Shopify theme from scratch because we need the same design with the main site.</p>
              </div>
          </div>
            <ModalVideo
            channel="youtube"
            isOpen={"fire" === isOpen}
            videoId="B2RSZy7rNpk"
            youtube={{
                autoplay: 1,
                mute: 1,
            }}
            onClose={() => setIsOpen(null)}
            />
          </div>

          <div tw="flex flex-col">
          <div tw="grid grid-cols-2 gap-8 py-12 items-center justify-between w-full">
                <div>
                <img src={peachdish_project} tw="cursor-pointer"/>
              </div>
              <div>
                <h2 tw="pb-4 break-words">Custom development + growth</h2>
                <img src={peachdish_logo} tw="w-40"/>
              </div>
          </div>
          <div tw="grid grid-cols-2 gap-8 justify-between w-full">
              <div>
               <p>We built a MVP very quickly to valify the idea, then continue improving the product for better user experience, marketing, and operation.</p>
               <p>It’s a multiple years project, we built everything from scratch, and help grow it to a real company.</p>
              </div>
              <div>
               <p>The project is built with Play framework and React, and it’s hosted on AWS Elastic Beanstalk.</p>
               <p>It’s a complex business, we have to integrate a lot of other systems to help business run smothly including Stripe, ShipStation, Twilio, Mailchimp, Mandrill, Kissmetrics etc.</p>
              </div>
          </div>
            
          </div>

          <div tw="flex flex-col">
          <div tw="grid grid-cols-2 gap-8 py-12 items-center justify-between w-full">
              <div>
                <h2 tw="pb-4 break-words">Custom development + growth</h2>
                <img src={perfect_logo} tw="w-40"/>
              </div>
              <div>
                <img src={perfect_project} tw="cursor-pointer" onClick={() => setIsOpen("perfect")}/>
              </div>
          </div>
          <div tw="grid grid-cols-2 gap-8 justify-between w-full">
              <div>
               <p>It's very complex meal kit subscription development, customers not only can choose meal kit, but also can customize the ingredients on the menu.</p>
               <p>We're using Prismic as CMS to host all the ingredient, menu information, and show them on the website through APIs.</p>
              </div>
              <div>
               <p>We also developed the admin dashboard to manage all the subscriptions, orders, users and shipments.</p>
               <p>It's integrated with Mandrill, so we can send transactional emails, the payment is Stripe, but we're not using Stripe subscription feature, we're managing the order renew ourselves because it's more flexible.</p>
              </div>
          </div>
            <ModalVideo
            channel="youtube"
            isOpen={"perfect" === isOpen}
            videoId="9lJ5LSTTBvw"
            youtube={{
                autoplay: 1,
                mute: 1,
            }}
            onClose={() => setIsOpen(null)}
            />
          </div>
          </div>
        </div>
      </ServiceItemWrapper>
      
    </main>
  )
}

export default withPreview(ProjectPage)


